import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { Page } from "../components/MenuComponent/MenuComponent.types";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { useInviteCode } from "../utils/useInviteCode";
import "../styles/research.styles.scss";

const ResearchPage: React.FC = () => {
  const { t } = useTranslation();
  const inviteCode = useInviteCode();

  return (
    <LayoutComponent page={Page.RESEARCH} seo={{ title: t("research.title") }}>
      <ContentRowComponent flexEnd background="#1B1B2B">
        <div className="padding-wrapper-120">
          <h2
            className="text-wrapper--h2 white"
            dangerouslySetInnerHTML={{
              __html: t("research.boxOne.title"),
            }}
          />
          <SeperatorComponent height={20} />
          <div
            className="text-wrapper--paragraph white"
            dangerouslySetInnerHTML={{
              __html: t("research.boxOne.content"),
            }}
          />
          <SeperatorComponent mobileOnly />
        </div>
        <StaticImage
          className="center-items full-height mobile-full-width"
          src="../assets/images/bedroom.jpeg"
          alt="MedBed Front"
          placeholder="blurred"
        />
      </ContentRowComponent>
      <ContentRowComponent noMinHeight className="center-items">
        <div className="research-detail-text center-text padding-wrapper-60">
          <SeperatorComponent height={30} />
          <h2
            className="text-wrapper--h2"
            dangerouslySetInnerHTML={{
              __html: t("research.boxTwo.title"),
            }}
          />
          <SeperatorComponent height={30} />
          <div
            className="text-wrapper--paragraph limited-text"
            dangerouslySetInnerHTML={{
              __html: t("research.boxTwo.content"),
            }}
          />
          <SeperatorComponent height={30} />
        </div>
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default ResearchPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
